import React from 'react'

export const Home = () => {
  return (
    <>
    <div className="banner-area text-capitalized bg-bottom text-light text-default Main_BG">
        <div className="container">
            <div className="double-items">
                <div className="row align-center top_Row">

                    <div className="col-lg-6 info">
                        <img src="assets/img/ZomaniWhiteLogo.png"
                           className="fadeInDown wow Main_Logo"
                            data-wow-delay="300ms" />
                        <h1 className="wow fadeInRight Mid_Row" data-wow-defaul="300ms">Zomani</h1>
                        <h2 className="wow fadeInUp" data-wow-delay="350ms">Content Generator</h2>
                        <p className="wow fadeInLeft" data-wow-delay="500ms">
                            Zomani is a tool that uses AI or NLP to automatically create
                            written content for use within Zoho CRM. It can save time and
                            effort by automating content creation, allowing users to focus
                            on other tasks.
                        </p>
                    </div>
                    <div className="col-lg-5 offset-lg-1 thumb wow fadeInRight" data-wow-delay="900ms">
                        <img src="assets/img/illustration/11.png" alt="Thumb" />
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div id="about" className="about-area default-padding">

        <div className="fixed-shape-left">
            <img src="assets/img/shape/9.png" alt="Shape" />
        </div>
 
        <div className="container">
            <div className="about-items">
                <div className="row align-center">
                    <div className="col-lg-6">
                        <div className="thumb">
                            <img className="wow fadeInLeft" data-wow-delay="300ms" src="assets/img/DashboardScreenShot.png"
                                alt="Thumb" />
                            <img className="wow fadeInUp" data-wow-delay="500ms" src="assets/img/Loader.JPG" alt="Thumb" />
                        </div>
                    </div>
                    <div className="col-lg-6 info wow fadeInRight">
                        <h4>Story About us</h4>
                        <h2>Introducing Zomani - the ultimate AI writing tool for Zoho CRM!</h2>
                        <ul>
                            <li>
                                <h5>Revolutionizing Content Creation:</h5>
                                <p>AI writing capabilities that can generate high-quality, unique content at scale.</p>
                            </li>
                            <li>
                                <h5>Streamlining Communication:</h5>
                                <p>
                                    The ability to generate and send bulk emails to a large recipient list.
                                </p>
                            </li>
                            <li>
                                <h5>High-volume Generation</h5>
                                <p>
                                    A high monthly word generation capacity of 100,000 words or more.
                                </p>
                            </li>
                            <li>
                                <h5>Adaptive Scalability:</h5>
                                <p>
                                    Scalability to adapt to the growing needs of the user.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>


   
    <div id="features" className="our-features-area text-center default-padding">

        <div className="fixed-bg Top_BG"></div>
   
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className="site-heading text-center">
                        <h2 className="area-title">Zomani Content Assistant</h2>
                        <div className="devider"></div>
                        <p>
                            Get assistance creating written content for Zoho CRM with this
                            automated tool.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="feature-items">
                <div className="row">
           
                    <div className="single-item col-lg-4 col-md-6">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-website"></i>
                          
                            </div>
                            <div className="info">
                                <h4>Email Generation</h4>
                                <p>
                                    Zomani can create personalized emails for use within Zoho
                                    CRM, including follow-up emails, customer service emails,
                                    and more.
                                </p>
                            </div>
                        </div>
                    </div>
                 
                    <div className="single-item col-lg-4 col-md-6">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-integration"></i>
                            </div>
                            <div className="info">
                                <h4>Document Creation</h4>
                                <p>
                                    Zomani can create documents such as contracts, proposals,
                                    and other written materials.
                                </p>
                            </div>
                        </div>
                    </div>
              
                    <div className="single-item col-lg-4 col-md-6">
                        <div className="item">
                            <div className="icon">
                                <i className="flaticon-rgb-1"></i>
                            </div>
                            <div className="info">
                                <h4>Bulk Emails</h4>
                                <p>
                                    Zomani Content Assistant can easily generate email content and send it to your
                                    multiple users in a few
                                    clicks.
                                </p>
                            </div>
                        </div>
                    </div>
               
                </div>
            </div>
        </div>
    </div>


   
    <div className="choose-us-area default-padding padding-bottom-extra bg-gray left-border">
        <div className="container">
            <div className="row align-center">
                <div className="col-lg-6 thumb">
                    <img src="assets/img/illustration/1.svg" alt="dashboard" />
                </div>
                <div className="col-lg-6 info">
                    <div className="item-box">
                        <h4>Why Choose Us</h4>
                        <h2>Efficient, Affordable, Quality</h2>
                        <p>
                            8 Reasons Why Our Zomani Content Generator App is the Best
                            Choice for Your Business
                        </p>
                        <ul>
                            <li>Time-Saving</li>
                            <li>Cost-Effective</li>
                            <li>High-Quality Content</li>
                            <li>Send Bulk Emails</li>
                            <li>Convenience</li>
                            <li>Wide Range of Content Types</li>
                            <li>Save and Reuse Content</li>
                            <li>Light and Dark Theme</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="overview" className="overview-area relative default-padding-top carousel-shadow">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className="site-heading text-center">
                        <h2>Quick Zomani Overview</h2>
                        <div className="devider"></div>
                        <p>
                            Zomani content writer is an AI-powered tool that generates
                            high-quality, error-free content quickly and efficiently.
                            Customizable to meet specific needs, it is a cost-effective and
                            convenient solution for businesses and individuals looking to
                            produce a large amount of content.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-full">
            <div className="row">
                <div className="col-lg-12">
                    <div className="overview-carousel owl-carousel owl-theme">
                 
                        <div className="item">
                            <img src="assets/img/DarkMode22222.jpg" alt="Thumb" />

                            <div className="content">
                                <h4><span>01</span> Content Generator</h4>
                            </div>
                        </div>
       
                        <div className="item">
                            <img src="assets/img/DashLogin.png" alt="Thumb" />
                            <div className="content">
                                <h4><span>02</span> Saved Templete</h4>
                            </div>
                        </div>
                     
                        <div className="item">
                            <img src="assets/img/DarkMode111.png" alt="Thumb" />
                            <div className="content">
                                <h4><span>03</span> Profile Setting</h4>
                            </div>
                        </div>
                     
                        <div className="item">
                            <img src="assets/img/ZomaniPopup.jpg" alt="Thumb" />
                            <div className="content">
                                <h4><span>04</span> Zoho Mail Wizard</h4>
                            </div>
                        </div>
            
                        <div className="item">
                            <img src="assets/img/ZomaniPopup2.jpg" alt="Thumb" />
                            <div className="content">
                                <h4><span>05</span> Content Generator for Email</h4>
                            </div>
                        </div>
            
                        <div className="item">
                            <img src="assets/img/ZomaniPopup3.jpg" alt="Thumb" />
                            <div className="content">
                                <h4><span>06</span> Content Generated for Eamil</h4>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <div id='about' className="process-area default-padding">
        <div className="container">
            <div className="row align-center">
                <div className="col-lg-6 thumb">
                    <img src="assets/img/illustration/7.png" alt="Thumb" />
                </div>
                <div className="col-lg-6 info ml-auto">
                    <h2>03 Simple Step's To Get Started</h2>
                    <ul>
                        <li>
                            <div className="icon">
                                <i className="flaticon-presentation"></i>
                            </div>
                            <div className="content wow fadeInUp" data-wow-delay="300ms">
                                <h4 className="text-capitalize">
                                    Install from Marketplace <span> Step - 01 </span>
                                </h4>
                                <p>
                                    You can easily install the Zomani content generator from
                                    <a href="https://marketplace.zoho.com/vendor/zenith-innovations">
                                        Zoho Marketplace
                                    </a>
                                    , giving you quick and convenient access to high-quality
                                    content at your fingertips.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="icon">
                                <i className="flaticon-target"></i>
                            </div>
                            <div className="content wow fadeInUp" data-wow-delay="500ms">
                                <h4 className="text-capitalize">
                                    Generate Multiple type Content <span> Step - 02 </span>
                                </h4>
                                <p>
                                    Zomani content generator allows you to easily produce a
                                    variety of content types, from blog posts and articles to
                                    social media posts and marketing copy.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="icon">
                                <i className="flaticon-report"></i>
                            </div>
                            <div className="content wow fadeInUp" data-wow-delay="700ms">
                                <h4 className="text-capitalize">
                                    Save and Reuse Generated Content <span> Step - 03 </span>
                                </h4>
                                <p>
                                    Zomani content generator lets you save and reuse your
                                    generated content, making it easy to repurpose and recycle
                                    your content for different purposes and platforms.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div id="pricing" className="pricing-area default-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className="site-heading text-center">
                        <h2>Our Packages</h2>
                        <div className="devider"></div>
                        <h4>No Hidden Price, Best Price For Your Business</h4>

                        <p>
                            Zomani offers best Pricing on its features. No hidden charges,
                            just pay as per usage. So let's go and grow.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="pricing-items">
                <div className="row" id="pricing_div">
             
                    <div className="col-lg-4 col-md-6 single-item">
                        <div className="pricing-item">
                            <div className="pricing-header">
                                <h4 className="text-center">Trial</h4>
                            
                                <h2><sup>$</sup>Free <sub>/ Monthly</sub></h2>
                            </div>
                            <ul>
                                <li>3 Languages</li>
                         
                                <li>
                                    2 Tones
                                </li>
                                <li>4 Usecases</li>
                                <li>Upto 10 History Items</li>
                                <li>10k words every month</li>
                                <li>Save Upto 10 Documents</li>
                                <li>Email Based Support</li>
                                <li className='Not_Allowed'> Save Templates</li>
                                <li className='Not_Allowed'> Send Bulk Emails</li>
                                <li className='Not_Allowed'> Access to Upcoming Premium Features</li>
                            </ul>
                        </div>
                    </div>
           
                    <div className="col-lg-4 col-md-6 single-item">
                        <div className="pricing-item">
                            <div className="pricing-header">
                                <h4 className="text-center">Basic</h4>
                                <h2><sup>$</sup>18 <sub>/ Monthly</sub></h2>
                            </div>
                            <ul>
                                <li>27+ Languages</li>
                                <li>7 Tones</li>
                                <li>10+ Usecases</li>
                                <li>Unlimited History Items</li>
                                <li>50k words every month</li>
                                <li>Save Upto 30+ Documents.</li>
                                <li>Email Based Support.</li>
                                <li>Save upto 30+ templates.</li>
                                <li>Upto 100 Bulk Emails.</li>
                                <li className='Not_Allowed'>Access to Upcoming Premium Features.</li>
                            </ul>
                        </div>
                    </div>
                  
                    <div className="col-lg-4 col-md-6 single-item">
                        <div className="pricing-item">
                            <div className="pricing-header">
                                <h4 className="text-center">Advanced</h4>
                                <h2><sup>$</sup>35 <sub>/ Monthly</sub></h2>
                            </div>
                            <ul>
                                <li>27+ Languages</li>
                                <li>11+ Tones</li>
                                <li>Unlimited Usecases</li>
                                <li>Unlimited History Items</li>
                                <li>Unlimited Words
                                </li>
                                <li>Save Unlimited Documents.</li>
                                <li>Live Chat and Video Demo</li>
                                <li>Save Unlimited Templates
                                </li>
                                <li>Unlimited Bulk Emails
                                    .</li>
                                <li>Access to Upcoming Premium Features.</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
    {/* <div className="testimonials-area carousel-shadow default-padding-top">

        <div className="fixed-shape-left">
            <img src="assets/img/shape/9.png" alt="Shape" />
        </div>
 
        <div className="container">
            <div className="testimonial-items">
                <div className="row align-center">
                    <div className="col-lg-7 review">
                        <h2>What people say about us</h2>
                        <div className="testimonial-carousel owl-carousel owl-theme">
                         
                            <div className="item">
                                <div className="thumb">
                                    <img src="assets/img/SulmanIlyas.jpg" alt="Author" />
                                </div>
                                <div className="info">
                                    <p>
                                        Zomani has saved us so much time and effort with automated
                                        content creation for our Zoho CRM. The customization
                                        options are a game-changer.
                                    </p>
                                    <h4>Sulman Ilyas</h4>
                                    <span>Managing Director</span>
                                </div>
                            </div>
                      
                            <div className="item">
                                <div className="thumb">
                                    <img src="assets/img/john-smith.jpg" alt="Author" />
                                </div>
                                <div className="info">
                                    <p>
                                        As a small business owner, Zomani has been a lifesaver for
                                        creating high-quality content quickly and easily.
                                    </p>
                                    <h4>John Smith</h4>
                                    <span>Marketing Manager</span>
                                </div>
                            </div>
                     
                            <div className="item">
                                <div className="thumb">
                                    <img src="assets/img/athisham.jpg" alt="Author" />
                                </div>
                                <div className="info">
                                    <p>
                                        Just started using Zomani and it's already made a huge
                                        difference in our content creation process.
                                    </p>
                                    <h4>Ahtisham Ali</h4>
                                    <span>Team Lead </span>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="col-lg-5 thum">
                        <img src="assets/img/illustration/12.png" alt="illustration" />
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </>
  )
}
