import React,{useEffect} from "react";
import Danger from "../../Assets/Images/danger.png"
import Success from "../../Assets/Images/success.png"
import Failed from "../../Assets/Images/fail.png"
export const LoadingScreen = () => {
  return (
    <div className="Loader-Body">
      <div className="Loader">
        <lottie-player
          src="https://assets5.lottiefiles.com/packages/lf20_5rImXbDsO1.json"
          background="transparent"
          speed="1"
          className
          loop
          autoplay
        ></lottie-player>
      </div>
      <div>
        <h3 className="LoaderHeading d-none">Loading...</h3>
      </div>
    </div>
  );
};



export const LoginFailed = () => {
  return (
    <div className="Loader-Body text-center">
<img src={Danger}>
</img>
<h3>Login Failed.<br/>
     Please <a href="#" onClick={()=>window.location.reload()}>Try Again</a></h3>
    </div>
  )
}
export const PaymentVerified = () => {
  useEffect(()=>{
localStorage.setItem("Verify_PaymentStatus","Success")
  },[])
  return (
    <div className="Loader-Body text-center">
<img src={Success}>
</img>
<h3>Payment Successful</h3>
  <h5>Please Visit the extension and reload, this page will close automatically in 3 seconds.</h5>
    </div>
  )
}
export const PaymentExpired = () => {
  useEffect(()=>{
    localStorage.setItem("Verify_PaymentStatus","Success")
      },[])
  return (
    <div className="Loader-Body text-center">
<img src={Danger}>
</img>
<h3>Link Expired</h3>
  <h5>This link has been expired, this page will close automatically in 3 seconds.</h5>
    </div>
  )
}
export const InvalidTransaction = () => {
  useEffect(()=>{
    localStorage.setItem("Verify_PaymentStatus","Success")
      },[])
  return (
    <div className="Loader-Body text-center">
<img src={Failed}>
</img>
<h3>Something went wrong</h3>
  <h5>Either the payment has failed or link is broken , this page will close automatically in 3 seconds.</h5>
    </div>
  )
}
export const PaymentFailed = () => {
  useEffect(()=>{
    localStorage.setItem("Verify_PaymentStatus","Success")
      },[])
  return (
    <div className="Loader-Body text-center">
<img src={Failed}>
</img>
<h3>Payment Failed</h3>
  <h5>Transaction is either canceled or failed , this page will close automatically in 3 seconds.</h5>
    </div>
  )
}
export const CancelSub = () => {
  useEffect(()=>{
    localStorage.setItem("Verify_PaymentStatus","Success")
  
      },[])
  return (
    <div className="Loader-Body text-center">
<img src={Success}>
</img>
<h3>Subscription Cancled SuccessFully</h3>
  <h5>Please refresh the extension, this page will close automatically in 3 seconds.</h5>
    </div>
  )
}
