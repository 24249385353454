import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../Assets/Styles/Header.css";
import Logo from  '../../Assets/Images/logo.png'
import LogoW from  '../../Assets/Images/logow.png'

export const Header = () => {
  const [Home, setHome] = useState("");
  const [Features, setFeatures] = useState("");
  const [Pricing, setPricing] = useState("");
  const [Reviews, setReviews] = useState("");
const [Route, setRoute] = useState("")
  const[Theme, setTheme]=useState(true)
 
useEffect(() => {
  
    SetActiveMenu()
  setTimeout(() => {
      var Loc = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    
      setRoute(Loc)
    })
}, [])
const topFunction=()=> {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

  const SetActiveMenu = () => {
    setTimeout(() => {
      var Loc = window.location.href.substring(
        window.location.href.lastIndexOf("#") + 1
      );
     
      if (Loc == "") {
        setHome("ActiveNav");
        setFeatures("");
        setPricing("");
        setReviews("")
      } else if (Loc == "features") {
        setHome("");
        setFeatures("ActiveNav");
        setPricing("")
        setReviews("");
      } else if (Loc == "pricing") {
        setHome("");
        setFeatures("");
        setPricing("ActiveNav");
        setReviews("")
      }
      else if(Loc == "about"){
        setHome("");
        setFeatures("");
        setPricing("");
        setReviews("ActiveNav")
      }
    }, 100);
   
  };
 

  

  return (
    <>
      <div className="Header_Body ">
        <div className="container d-flex justify-content-between">
      <div className="d-flex justify-content-start">
        
      <Link to="/">
          {" "}
          {/* <button className="nav-link btn">Zomani</button> */}
    {Theme?
          <img className="LogoImg m-2" src={Logo} width={150}></img>
          :
          <img className="LogoImg m-2" src={LogoW} width={150}></img>}
        </Link>
      </div>

        <div className="d-flex justify-content-end px-3" >
   
         { Route.includes("pricing")?     <a
                href="/"
                className={"nav-link " + Home}
                id="HomeNav"
               
              >
               Home{" "}
              </a>
            :
            <a
            href="#"
            className={"nav-link " + Home}
        
            onClick={()=>{SetActiveMenu();topFunction()}}
          >
            {" "}
           Home
          </a> 
            }
        
              <a
                href="#about"
                className={"nav-link " + Reviews}
            
                onClick={SetActiveMenu}
              >
                {" "}
               About
              </a>
              <a
                href="#features"
                className={"nav-link " + Features}
        onClick={SetActiveMenu}
              
              >
                {" "}
               Features
              </a>
             
              <a
                href="#pricing"
                className={"nav-link " + Pricing}
                onClick={SetActiveMenu}
                
              >
                {" "}
                 Pricing
              </a>
  
             
            
              <a
                href="https://marketplace.zoho.com/app/preview/crm/zomani"
                className={"mt-3" }
                target="_blank"
              
              >
                {" "}
                
                <button className="btn InstallBtn">Install Now</button>
              </a>
            
              
             
          
        </div>
        </div>
      </div>
    </>
  );
};
