import React, { useEffect } from 'react'
import { Addons } from '../Components/GlobalComponents.jsx/Addons'

export const Pricing = () => {

useEffect(() => {
  

  
}, [])



  return (
    <><Addons/></>
  )
}
