import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from './Screens/Home';
import { Toaster } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { Pricing } from './Screens/Pricing';
import { Payments } from './Screens/Auth/Payments';
import { DirectZohoLogin, GetWriterData } from './Utils/apicalls';
import { DecryptVal } from './Utils/Encryption';
import { CancelSub, LoadingScreen, LoginFailed } from './Components/GlobalComponents.jsx/LoadingScreen';
import { Header } from './Components/GlobalComponents.jsx/Header';






export default function App() {

  const [Login_User, setLogin_User] = useState(undefined)
  useEffect(() => {
  
    var urlSearchParams = new URLSearchParams(window.location.search);
    
    var params = Object.fromEntries(urlSearchParams.entries());
  
    if(Object.keys(params).length === 0 ){
     
      
      var resp= GetWriterData()
      resp.then((Data)=>{
       localStorage.clear();
        localStorage.setItem("WriterData",JSON.stringify(Data))
        setLogin_User("Direct")
      })
    }

else if(params.Payments_Stripe_Zomani!==undefined)
{
  setLogin_User("Payment")
}
    else{
      var DecryptedParams={}
      Object.keys(params).forEach((item)=>{
      var  Parameter=params[item]
        DecryptedParams[item]=decodeURIComponent(DecryptVal(Parameter))
      })


    console.log(DecryptedParams)
    var resp=DirectZohoLogin(DecryptedParams);

    resp.then((Data)=>{
      if(Data!==null){
      if (Data.Status == "Valid" || Data.Status == "New_Valid"){
    setLogin_User("true")

      }
    
      else{
    setLogin_User("false")
      }
    }
    else{
      setLogin_User("false")
    }
    })
    }
  
  
  }, [])




  if(Login_User==undefined){
    return(<LoadingScreen/>)
     }
      else if(Login_User=="true"){
      return (
        
        <BrowserRouter>
 <Header/>
        <Toaster/>
        <div>
          <Routes>
          <Route path='*' element={<Home/>}/>
              <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/`}
                    element={<Home/>}
                  />
               
              <Route exact path="/pricing" element={<Pricing/>} />
              <Route exact path="/payments" element={<Payments/>} />
              <Route exact path="/cancelsuccess" element={<CancelSub/>} />
          </Routes>
          </div>
      
          </BrowserRouter>
      );
    }
 
    else if (Login_User=="Direct"){
      return ( <BrowserRouter>
 <Header/>
      <Toaster/>
      <div>
        <Routes>
        <Route path='*' element={<Home/>}/>
            <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  element={<Home/>}
                />
          
            <Route exact path="/pricing" element={<Pricing/>} />
          
        </Routes>
        </div>
    
        </BrowserRouter>)
    }
    else if(Login_User=="Payment"){
      return(
        <Payments />
        )
    }
    else{
      return(
        <LoginFailed/>
      )
    }
}


