import { CancelSubEndpoint, ChangePlanEndpoint, SubscribeEndpoint, VerifyEndpoint } from "./endpoints";

function PostAuth(Body, Endpoint ){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+localStorage.getItem("AuthToken"));
    myHeaders.append("Content-Type", "application/json");
    
    var raw = Body
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
   return fetch(Endpoint, requestOptions)
      .then(response => response.json ())
      .then(result => {
    return result
    })
      .catch(error => {
    return null
    });
}

export const Subscribe_Addon=async(ContentObj)=>{

  
    var DataObj=ContentObj
       DataObj.userEmail=JSON.parse(localStorage.getItem("LoginResp")).Details.login_email
       var raw=JSON.stringify(DataObj)
       var Endpoint=SubscribeEndpoint
       var res= PostAuth(raw,Endpoint )
    
       return res
   }
   export const ChangeAddon=async(ContentObj)=>{

  
    var DataObj=ContentObj
       DataObj.userEmail=JSON.parse(localStorage.getItem("LoginResp")).Details.login_email
       var raw=JSON.stringify(DataObj)
       var Endpoint=ChangePlanEndpoint
       var res= PostAuth(raw,Endpoint )
    
       return res
   }
   export const VefiryPurchase=async(ContentObj)=>{

  
    var DataObj=ContentObj

       var raw=JSON.stringify(DataObj)
       var Endpoint=VerifyEndpoint
       var res= PostAuth(raw,Endpoint )
    
       return res
   }
   export const Unsubscribe=(Content)=>{
    var DataObj=Content
    var raw=JSON.stringify(DataObj)
    var Endpoint=CancelSubEndpoint
    var res= PostAuth(raw,Endpoint )
    return res
  }
  export const GetWriterData=()=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
   return fetch("https://zomani.api-apx.com/api/v1/Get_Supporting_data", requestOptions)
      .then(response => response.json())
      .then(result => {
return result
      })
      .catch(error =>{ return null});
   }
  export const DirectZohoLogin=(UserObj)=>{
  
      localStorage.clear();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(UserObj);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

  return  fetch(
      "https://zomani.api-apx.com/api/v1/Authenticate_Direct",
      requestOptions
    )
      .then((response) => response.json())
      .then(async(result) => {
     
        if (result.Status == "Valid" || result.Status == "New_Valid") {
          
     var DataResp= await GetWriterData();
     localStorage.setItem("WriterData", JSON.stringify(DataResp))     
        } 
       
            localStorage.setItem("LoginStatus", "true");
                      localStorage.setItem("LoginResp", JSON.stringify(result));
                      localStorage.setItem("AuthToken", result.JToken);
                
         
        return result
      })
      .catch((error) => {
       

     
        return null;
      });
 
    }