import React, { useEffect, useState } from "react";
import { InvalidTransaction, LoadingScreen, PaymentExpired, PaymentFailed, PaymentVerified } from "../../Components/GlobalComponents.jsx/LoadingScreen";

import { VefiryPurchase } from "../../Utils/apicalls";
import { DecryptVal } from "../../Utils/Encryption";

export const Payments = () => {
  const [PaymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {



    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var VerificationObj = {
      Status: params.Status,
      AddOnID: params.AddOnID,
    };

   
      var resp = VefiryPurchase(VerificationObj);
      resp.then((data) => {
        if (data !== null) {
          setPaymentStatus(data);
        }

        setTimeout(() => {
          window.close();
        },3000);
      }
      );
    
  },[]);

  return (
<>

{
  PaymentStatus===""?
  <LoadingScreen/>:
  PaymentStatus==="Verified"?
  <PaymentVerified/>
  :
  PaymentStatus==="Already Verified"?
  <PaymentExpired/>
  :
PaymentStatus=="Failed Transaction"?
<PaymentFailed/>
:
PaymentStatus=="Invalid Transaction"?
<InvalidTransaction/>
:
<></>

}


</>

  );
};
