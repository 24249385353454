import React, { useEffect, useState } from "react";
// import { PricingPlans } from "../../Utils/dummydata";
import "../../Assets/Styles/Addons.css";
import { ChangeAddon, GetWriterData, Subscribe_Addon, Unsubscribe } from "../../Utils/apicalls";
import { toast } from "react-hot-toast";
import { ConfirmationModal } from "./ConfirmationModal";
import 'animate.css';
import { useNavigate } from "react-router";
import { CancelModal } from "./CancelModal";


export const Addons = () => {
  const [Cancel_Modal, setCancel_Modal] = useState(false)
  const PricingPlans =
    localStorage.getItem("WriterData") == null ||
    localStorage.getItem("WriterData") == undefined
      ? []
      : JSON.parse(localStorage.getItem("WriterData")).PricingPlans;
      const PricingPlansYearly =
    localStorage.getItem("WriterData") == null ||
    localStorage.getItem("WriterData") == undefined
      ? []
      : JSON.parse(localStorage.getItem("WriterData")).PricingPlansYearly
      ;
  // const PricingPlans =TestData.PricingPlans
  // const PricingPlansYearly =TestData.PricingPlansYearly

  const [UserPlan, setUserPlan] = useState(undefined);
  const [MOdal, setMOdal] = useState(false);
  const [CurrItem, setCurrItem] = useState({});
  const [PlanType, setPlanType] = useState(true)
  const navigate = useNavigate();
  const SubscribePlan = (Item) => {
    var Domain = "";

  
      Domain = window.location.protocol + "//" + window.location.host + "/";
    

    var SubscriptionObj = {
      addOnName: Item.AddonTitle,
      addOnPrice: Item.Price,
      addOnType: "subscription",
      addOnStatus: "New",
      addOnIdentifier: Item.AddonIdentifier,

      Item_Mode: "subscription",
      stripe_identifier: Item.PriceID,
      MainDomain: Domain,
    };
    var resp = Subscribe_Addon(SubscriptionObj);

    toast.promise(
      resp,

      {
        loading: "Please Wait",
        success: (data) => {
          if (data !== null) {
            if (data.Status == "Success") {
              window.open(data.RedirectUrl, "_blank");
              return "Redirecting To Payment Gateway";
            }
          } else {
            toast.error("Something went wront please try again");
          }
        },
        error: () => {
          toast.error("Something went wront please try again");
        },
      }
    );
  };
  const ChangePlan = (Item) => {
    var Domain = "";
    var PreviousSub = JSON.parse(localStorage.getItem("LoginResp")).Details
      .Subscriptions[0].StripeIdentifier;

    if (window.location.href.includes("zappsusercontent")) {
      Domain = localStorage.getItem("WidgetLink");
    } else {
      Domain = window.location.protocol + "//" + window.location.host + "/";
    }

    var SubscriptionObj = {
      addOnName: Item.AddonTitle,
      addOnPrice: Item.Price,
      addOnType: "subscription",
      addOnStatus: "New",
      addOnIdentifier: Item.AddonIdentifier,

      Item_Mode: "subscription",
      stripe_identifier: Item.PriceID,
      MainDomain: Domain,
      PreviousSub_Id: PreviousSub,
    };
    var resp = ChangeAddon(SubscriptionObj);

    toast.promise(
      resp,

      {
        loading: "Please Wait",
        success: (data) => {
          if (data !== null) {
            if (data.Status == "Success") {
              window.open(data.RedirectUrl, "_blank");
              return "Redirecting To Payment Gateway";
            }
          } else {
            toast.error("Something went wront please try again");
          }
        },
        error: () => {
          toast.error("Something went wront please try again");
        },
      }
    );
  };


  const TriggerPlanChange = (Item) => {
    setCurrItem(Item);
    setMOdal(true);
  };
  useEffect(() => {
if(JSON.parse(localStorage.getItem("LoginResp")==undefined))
{
setUserPlan("Direct")

}

else{
  if (JSON.parse(localStorage.getItem("LoginResp")).Details.Subscriptions !== undefined) {
    var User_Plan = JSON.parse(localStorage.getItem("LoginResp")).Details
      .Subscriptions[0].AddOn_Identifier;
    setUserPlan(User_Plan);
  }
}

   
  
  
    window.addEventListener("storage", (e) => {
      if (e.key == "Verify_PaymentStatus") {
        if (e.newValue == "Success") {
       
         
         setTimeout(() => {
        
          window.location.reload()
         
         }, 1000);
        }
      }
    });
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener("storage", () => {
        alert("removed");
      });
    },
    []
  );

  const CancelSubscription=()=>{
    var CancelObj={
      userEmail: JSON.parse(localStorage.getItem("LoginResp")).Details.login_email,
      CurrentSub_Id:JSON.parse(localStorage.getItem("LoginResp")).Details.Subscriptions[0].StripeIdentifier
    }
    
      var Response= Unsubscribe(CancelObj)
      toast.promise(
        Response,
    
        {
          loading: "Please Wait",
          success: (data) => {
            if (data !== null) {
              if (data.Status == "Success") {
                navigate("/cancelsuccess")
                setTimeout(() => {
                 navigate("/")
                  
                }, 2000);
                return "Subscription Canceled";
              }
            } else {
              toast.error("Something went wront please try again");
            }
          },
          error: () => {
            toast.error("Something went wront please try again");
          },
        }
      );
    
    
    }




  return (
    <>
      <div className="AddonsBody">
      {Cancel_Modal?
      <CancelModal CloseModal={()=>setCancel_Modal(false)} CancelSubscription={CancelSubscription}/>
    :
    <></>
    }
        <div className="Addon_Heading text-center">
          <h2>
            <strong>Ready To Get Started?</strong>
          </h2>
         <h5>Choose a plan tailored to your needs.</h5>
          <div className="Pricing_Toggle d-flex justify-content-center">
              <button className={PlanType?" btn  ActivePricing":" btn Prcing_Button"} onClick={()=>setPlanType(true)}>Monthly</button>
              <button className={!PlanType?" btn  ActivePricing":" btn Prcing_Button"} onClick={()=>setPlanType(false)}>Yearly</button>
          </div> 
        </div>
        <div className="container mt-5">
        
          <div className="row mt-3">
          { 
          PlanType?
          <>
          
          {PricingPlans.map((item, Key) => {
              return (
                <div className="col-lg-4" key={Key}>
                  <div className={"card AddonItem  animate__animated animate__fadeInLeft AddonItem_" + Key}>
                  {item.PopularStatus=="true"?
                    <div className="ribbon ribbon-top-right"><span>Popular</span></div>
                  :
                  <></>}
                    <div className="Addon_Title ">
                      <div className="mt-2">
                        <div className={"Pricing_Icon mb-3 Icon_" + Key}>
                          <i className={item.AddonIcon + " Pricing_Image"}></i>
                        </div>
                        <h5 className="mt-4">{item.AddonTitle}</h5>
                        <div className="ItemPrice d-flex justify-content-center ">
                          <div className="InnnerPrice">
                        {item.Price=="Free"?
                        <h4 className="float-right">{item.Price}</h4>
                        :
                        <>
                        <h4 className="float-right">${item.Price}</h4>
                            <small>/month</small>
                            </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Addon_Body">
                      <ul className="ItemsList">
                        { item.ItemsInPlan.map((innerItem, innerKey) => {
                          var Data = "";
                          innerItem.Items.forEach((element) => {
                            Data += element + ",";
                          });
                          if (innerItem.ItemsStatus == "true") {
                            return (
                              <li
                                key={innerKey}
                                className="mb-2 d-flex PricingItem"
                              >
                                <i
                                  className="fa-solid fa-check CheckIcon"
                                  title={Data}
                                ></i>{" "}
         
                                <div>
                                  <h5 id={"Testing_" + innerKey}>
                                    {innerItem.ItemName}{" "}
                                    {Data!==""?
                                    <i className="fa-regular fa-circle-question" title={Data}></i>
                                  :
                                  <></>
                                  }
                                  </h5>
                                  
                                  <span>{innerItem.ItemSub} </span>{" "}
                                </div>
                              </li>
                            );
                          } else {
                            return (
                              <li
                                key={innerKey}
                                className="mb-2 NotAllowd d-flex PricingItem"
                              >
                                <i className="fa-solid fa-xmark "></i>{" "}
                                <div>
                                  <h5>{innerItem.ItemName}</h5>
                                  <span>{innerItem.ItemSub}</span>{" "}
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>

                    <div className="subscribeButton">
                      {
                        UserPlan == "Direct" ? (
                          <a href="https://marketplace.zoho.com/app/preview/crm/zomani" target="_blank" className="w-100">
                          <button
                            className="btn PurchaseBtn"
                            
                          >
                           Get Started
                          </button>
                          </a>
                        )
                        :

                      UserPlan == undefined &&  item.AddonIdentifier=="Trial_Plan" ? (
                        <button
                        disabled
                          className="btn PurchaseBtn DisabledBtn"
                    
                        >
                          Active
                        </button>
                      )
                      :UserPlan==undefined && item.AddonIdentifier!="Trial_Plan"?

                      <button
                      className="btn PurchaseBtn"
                      onClick={()=>SubscribePlan(item)}
                    >
                      Choose Plan
                    </button>
                      
                      : UserPlan == item.AddonIdentifier ? (
                        <button
                          className="btn PurchaseBtn DisabledBtn"
                          disabled
                        >
                          Active Plan
                        </button>

                        

                      ) 
                      :item.AddonIdentifier=="Trial_Plan" && item.AddonIdentifier !== UserPlan?
                      <button
                      className="btn PurchaseBtn"
                      onClick={()=>setCancel_Modal(true)}
                    >Cancel Subscription</button>
                      
                      : UserPlan === "Basic_Plan" &&
                        item.AddonIdentifier !== UserPlan ? (
                        <button
                          className="btn PurchaseBtn"
                          onClick={() => TriggerPlanChange(item)}
                        >
                          Upgrade
                        </button>
                      ) : UserPlan === "Pro_Plan" && item.AddonIdentifier == "Basic_Plan" ? (
                        <button
                          className="btn PurchaseBtn"
                          onClick={() => TriggerPlanChange(item)}
                        >
                          Downgrade
                        </button>
                      ) : UserPlan === "Pro_Plan" &&
                        item.AddonIdentifier === "Advanced_Plan" ? (
                        <button
                          className="btn PurchaseBtn"
                          onClick={() => TriggerPlanChange(item)}
                        >
                          Upgrade
                        </button>
                      ) : (
                        <button
                          className="btn PurchaseBtn"
                          onClick={() => TriggerPlanChange(item)}
                        >
                          Downgrade
                        </button>
                      )}
                    </div>
                  </div>
                  {MOdal ? (
                    <ConfirmationModal
                      Item={CurrItem}
                      CloseModal={() => setMOdal(false)}
                      TriggerAPI={ChangePlan}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
            </>
            :
            <>
            {PricingPlansYearly.map((item, Key) => {
                return (
                  <div className="col-lg-4" key={Key}>
                    <div className={"card AddonItem animate__animated animate__fadeInRight AddonItem_" + Key}>
                   {item.Discount=="0%"?
                   <></>:
                    <div className="ribbon ribbon-top-right"><span>-{item.Discount}</span></div>}
                      <div className="Addon_Title ">
                        <div className="mt-2">
                          <div className={"Pricing_Icon mb-3 Icon_" + Key}>
                            <i className={item.AddonIcon + " Pricing_Image"}></i>
                          </div>
                          <h5 className="mt-4 text-white">{item.AddonTitle}</h5>
                          <div className="ItemPrice d-flex justify-content-center ">
                            <div className="InnnerPrice">
                            {item.Price=="Free"?
                        <h4 className="float-right">{item.Price}</h4>
                        :
                        <>
                              <h4 className="float-right">${item.Price}</h4>
                              <small>/year</small>
                            </>
                            }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Addon_Body">
                        <ul className="ItemsList">
                          {item.ItemsInPlan.map((innerItem, innerKey) => {
                            var Data = "";
                            innerItem.Items.forEach((element) => {
                              Data += element + ",";
                            });
                            if (innerItem.ItemsStatus == "true") {
                              return (
                                <li
                                  key={innerKey}
                                  className="mb-2 d-flex PricingItem"
                                >
                                  <i
                                    className="fa-solid fa-check CheckIcon"
                                    title={Data}
                                  ></i>{" "}
           
                                  <div>
                                    <h5 id={"Testing_" + innerKey}>
                                      {innerItem.ItemName}{" "}
                                      {Data!==""?
                                      <i className="fa-regular fa-circle-question" title={Data}></i>
                                    :
                                    <></>
                                    }
                                    </h5>
                                    
                                    <span>{innerItem.ItemSub} </span>{" "}
                                  </div>
                                </li>
                              );
                            } else {
                              return (
                                <li
                                  key={innerKey}
                                  className="mb-2 NotAllowd d-flex PricingItem"
                                >
                                  <i className="fa-solid fa-xmark "></i>{" "}
                                  <div>
                                    <h5>{innerItem.ItemName}</h5>
                                    <span>{innerItem.ItemSub}</span>{" "}
                                  </div>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
  
                      <div className="subscribeButton">
                        { UserPlan == "Direct" ? (
                          <a href="https://marketplace.zoho.com/app/preview/crm/zomani" target="_blank" className="w-100">
                          <button
                            className="btn PurchaseBtn"
                            
                          >
                           Get Started
                          </button>
                          </a>
                        )
                        :
                        UserPlan == undefined  && item.AddonIdentifier=="Trial_Plan"? (
                         <button
                         disabled
                           className="btn PurchaseBtn DisabledBtn"
                     
                         >
                           Active
                         </button>
                       )
                       :UserPlan==undefined && item.AddonIdentifier!="Trial_Plan"?
 
                       <button
                       className="btn PurchaseBtn"
                       onClick={()=>SubscribePlan(item)}
                     >
                       Choose Plan
                     </button> : UserPlan == item.AddonIdentifier ? (
                          <button
                            className="btn PurchaseBtn DisabledBtn"
                            disabled
                          >
                            Active
                          </button>
                        ) 
                        :item.AddonIdentifier=="Trial_Plan" && item.AddonIdentifier !== UserPlan?
                        <button
                        className="btn PurchaseBtn"
                        onClick={()=>setCancel_Modal(true)}
                      >Cancel Subscription</button>
                        : UserPlan === "Basic_Plan_Yearly" &&
                          item.AddonIdentifier !== UserPlan ? (
                          <button
                            className="btn PurchaseBtn"
                            onClick={() => TriggerPlanChange(item)}
                          >
                            Upgrade
                          </button>
                        ) : UserPlan === "Pro_Plan_Yearly" && item.AddonIdentifier == "Basic_Plan_Yearly" ? (
                          <button
                            className="btn PurchaseBtn"
                            onClick={() => TriggerPlanChange(item)}
                          >
                            Downgrade
                          </button>
                        ) : UserPlan === "Pro_Plan_Yearly" &&
                          item.AddonIdentifier === "Advanced_Plan_Yearly" ? (
                          <button
                            className="btn PurchaseBtn"
                            onClick={() => TriggerPlanChange(item)}
                          >
                            Upgrade
                          </button>
                        ) : 
                        UserPlan=="Advanced_Plan_Yearly"?
                        (
                          <button
                            className="btn PurchaseBtn"
                            onClick={() => TriggerPlanChange(item)}
                          >
                            Downgrade
                          </button>
                        )
                      :
                      (
                        <button
                          className="btn PurchaseBtn"
                          onClick={() => TriggerPlanChange(item)}
                        >
                          Upgrade
                        </button>
                      )
                      }
                      </div>
                    </div>
                    {MOdal ? (
                      <ConfirmationModal
                        Item={CurrItem}
                        CloseModal={() => setMOdal(false)}
                        TriggerAPI={ChangePlan}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
              </>
            
            }


          </div>
        </div>
      </div>
    </>
  );
};
