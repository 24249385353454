import React from 'react'

export const CancelModal = (props) => {
    const TriggerSave=()=>{

        props.CancelSubscription()
        props.CloseModal()
    }
  return (
    <div className="ChangePlan">
      <div className="ChangeModal">
        <div className="Modal-Header">
          <h4>Confirmation</h4>
          <h4 onClick={() => props.CloseModal()}>
            <i className="fa-solid fa-close"></i>
          </h4>
        </div>

        <div className="Modal-Body text-center">
          <h4>Are you sure you want to cancel?</h4>
          <div className="d-flex justify-content-end pt-4">
            <button
              className="btn btn-danger "
              onClick={() => props.CloseModal()}
            >
              Cancel
            </button>
            <button
              className="btn btn-success Save_Btn"
              onClick={() => TriggerSave()}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
